import {AutoLayout, DropdownFilter, FlatButton} from '@ambler/andive'
import {FixType, assertUnreachable, isIn} from '@ambler/shared'
import React from 'react'
import {DayPickerRangeController, FocusedInputShape} from 'react-dates'
import styled, {createGlobalStyle} from 'styled-components'
// @ts-ignore FixType
import {END_DATE, START_DATE} from 'react-dates/constants'
import 'react-dates/initialize'

import moment, {AmblerMoment as Moment} from '@ambler/moment'

import {Andiv, ArrowLeftIcon, ArrowRightIcon, DropdownArrowIcon, FilterButton, Text, palette} from '@ambler/andive-next'
import {capitalize} from 'lodash'
import {AppRow, useMobile, useScreenClass} from './stack/responsive'

import {HSpace} from './bo-andive'

export const ReactDatesDatePickerCss = createGlobalStyle`
  .PresetDateRangePicker_panel {
    padding: 0 22px 11px
  }
  .PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer
  }
  .PresetDateRangePicker_button:active {
    outline: 0
  }
  .PresetDateRangePicker_button__selected {
    color: ${palette.amblea.white};
    background: #00a699
  }
  .SingleDatePickerInput {
    background-color:${palette.amblea.white};
    display: inline-block;
  }
  .SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
  }
  .SingleDatePickerInput__rtl {
    direction: rtl
  }
  .SingleDatePickerInput__disabled {
    background-color: #f2f2f2
  }
  .SingleDatePickerInput__block {
    display: block
  }
  .SingleDatePickerInput__showClearDate {
    padding-right: 30px
  }
  .SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
  }
  .SingleDatePickerInput_clearDate__small {
    padding: 6px
  }
  .SingleDatePickerInput_clearDate__hide {
    visibility: hidden
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
  }
  .SingleDatePickerInput_clearDate_svg__small {
    height: 9px
  }
  .SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
  }
  .SingleDatePicker {
    position: relative;
    display: inline-block
  }
  .SingleDatePicker__block {
    display: block
  }
  .SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
  }
  .SingleDatePicker_picker__rtl {
    direction: rtl
  }
  .SingleDatePicker_picker__directionLeft {
    left: 0
  }
  .SingleDatePicker_picker__directionRight {
    right: 0
  }
  .SingleDatePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  .SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
  }
  .SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
  }
  .SingleDatePicker_closeButton:focus,
  .SingleDatePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
  }
  .SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
  }
  .DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
  }
  .DayPickerKeyboardShortcuts_show {
    width: 22px;
    position: absolute;
    z-index: 2
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover {
    border-right: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover {
    border-left: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: -28px
  }
  .DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: -28px
  }
  .DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: -28px
  }
  .DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px
  }
  .DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
  }
  .DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
  }
  .DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
  }
  .DayPickerKeyboardShortcuts_close:active {
    outline: 0
  }
  .DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
  .DayPickerKeyboardShortcuts_closeSvg:focus,
  .DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
  }
  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
  }
  .CalendarDay:active {
    outline: 0
  }
  .CalendarDay__defaultCursor {
    cursor: default
  }
  .CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: ${palette.amblea.blue[800]};
    background: #fff
  }
  .CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
  }
  .CalendarDay__outside {
    border: 0;
    background: #fff;
    color: ${palette.amblea.blue[800]}
  }
  .CalendarDay__outside:hover {
    border: 0
  }
  .CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: ${palette.amblea.blue[800]}
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: ${palette.amblea.blue[800]}
  }
  .CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
  }
  .CalendarDay__last_in_range,
  .CalendarDay__last_in_range:hover {
    border-style: solid
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
  }
  .CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
  }
  .CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  .CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate
  }
  .CalendarMonth_caption {
    color: ${palette.amblea.blue[800]};
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
  }
  .CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
  }
  .CalendarMonthGrid__animating {
    z-index: 1
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
  }
  .CalendarMonthGrid__vertical {
    margin: 0 auto
  }
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2
  }
  .DayPickerNavigation__horizontal {
    height: 0
  }
  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
  }
  .DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: ${palette.amblea.grey[600]}
  }
  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
  }
  .DayPickerNavigation_button__default:active {
    background: #f2f2f2
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
  }
  .DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
  }
  .DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: ${palette.amblea.blue[800]}
  }
  .DayPicker {
    background: #fff;
    position: relative;
    text-align: left
  }
  .DayPicker__horizontal {
    background: #fff
  }
  .DayPicker__verticalScrollable {
    height: 100%
  }
  .DayPicker__hidden {
    visibility: hidden
  }
  .DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
    border-radius: 3px
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
  }
  .DayPicker_portal__vertical {
    position: initial
  }
  .DayPicker_focusRegion {
    outline: 0
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
  }
  .DayPicker_weekHeaders {
    position: relative
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px
  }
  .DayPicker_weekHeader {
    color: ${palette.amblea.grey[600]};
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
  }
  .DayPicker_weekHeader__vertical {
    left: 50%
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
  }
  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
  }
  .DayPicker_transitionContainer__horizontal {
    -webkit-transition: height .2s ease-in-out;
    -moz-transition: height .2s ease-in-out;
    transition: height .2s ease-in-out
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
  }
  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle
  }
  .DateInput__small {
    width: 97px
  }
  .DateInput__block {
    width: 100%
  }
  .DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
  }
  .DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: ${palette.amblea.blue[800]};
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0
  }
  .DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 7px 7px 5px
  }
  .DateInput_input__regular {
    font-weight: auto
  }
  .DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  .DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #008489;
    border-left: 0
  }
  .DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
  }
  .DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }
  .DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
  }
  .DateInput_fangShape {
    fill: #fff
  }
  .DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
  }
  .DateRangePickerInput {
    background-color: #fff;
    display: inline-block
  }
  .DateRangePickerInput__disabled {
    background: #f2f2f2
  }
  .DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
  }
  .DateRangePickerInput__rtl {
    direction: rtl
  }
  .DateRangePickerInput__block {
    display: block
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 30px
  }
  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: ${palette.amblea.blue[800]}
  }
  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: ${palette.amblea.blue[800]};
    height: 24px;
    width: 24px
  }
  .DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .DateRangePickerInput_clearDates__small {
    padding: 6px
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: hidden
  }
  .DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
  }
  .DateRangePickerInput_clearDates_svg__small {
    height: 9px
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
  }
  .DateRangePicker {
    position: relative;
    display: inline-block
  }
  .DateRangePicker__block {
    display: block
  }
  .DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
  }
  .DateRangePicker_picker__rtl {
    direction: rtl
  }
  .DateRangePicker_picker__directionLeft {
    left: 0
  }
  .DateRangePicker_picker__directionRight {
    right: 0
  }
  .DateRangePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  .DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
  }
  .DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
  }
  .DateRangePicker_closeButton:focus,
  .DateRangePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
  }
  .DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
`
export const ReactDatesAmblerTheme = createGlobalStyle`
  /* Prev/Next navigation */

  .DayPicker_focusRegion {
    position: relative;
  }

  .DayPickerNavigation {
    position: absolute;

    height: 40px;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .DayPickerNavigation_button {
    outline: 0;
  }

  /* Calendar month label override */

  .CalendarMonth_caption {
    text-transform: capitalize;
  }

  /* Calendar days matrice override */

  .CalendarDay__default {
    background: white !important;
    border: none !important;
  }

  .CalendarDay__default:hover {
    background: none !important;
    border: none !important;
  }
  .CalendarDay__default:hover .CalendarDay__AmblerDay {
    background-color: ${palette.amblea.grey[100]};
  }

  .CalendarDay__selected_span {
    background: none;
    border: none;
  }
  .CalendarDay__selected_span .CalendarDay__AmblerDay {
    background: ${palette.amblea.tea[200]};
    color: ${palette.amblea.grey[600]};
  }

  .CalendarDay__selected {
    background: none;
    border: none;
  }
  .CalendarDay__selected .CalendarDay__AmblerDay {
    background: ${palette.amblea.tea[600]};
    color: white;
  }

  .CalendarDay__selected:hover {
    background: none;
    border: none;
  }
  .CalendarDay__selected:hover .CalendarDay__AmblerDay {
    background: ${palette.amblea.tea[600]};
    color: white;
  }

  .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: none;
    border: none;
  }
  .CalendarDay__selected_span:active .CalendarDay__AmblerDay,
  .CalendarDay__selected_span:hover .CalendarDay__AmblerDay {
    background: ${palette.amblea.tea[600]};
    color: white;
  }

  .CalendarDay__hovered_span {
    & div {
      background: ${palette.amblea.tea[200]};
      color: ${palette.amblea.grey[600]};
    }
  }
`

const PrevIcon = styled(ArrowLeftIcon).attrs({color: palette.amblea.tea[600]})`
  position: relative;
  top: 8px;
  left: 8px;
`

const NextIcon = styled(ArrowRightIcon).attrs({color: palette.amblea.tea[600], right: true})`
  position: relative;
  top: 8px;
  left: calc(100% - 24px - 8px);
`

const Day = styled.div`
  border-radius: 50%;

  height: 80%;
  width: 80%;

  margin: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
`

function renderDay(day: FixType) {
  // To stylize each cases (hover, selected, hover-selected) we need to "hook" on
  // react date theme override style and identify this smaller <div/> to have
  // smaller -- not touching -- circles. See lib/react-dates/ambler-theme.js for the
  // css selection forwarding to the following CalendarDay_AmblerDay class.
  return <Day className="CalendarDay__AmblerDay">{moment(day).format('D')}</Day>
}

function renderMonth(month: FixType) {
  return (
    // The default react-dates implementation is month.format('MMMM YYYY').
    // We want month.format('MMMM') for the current year.
    month.format('MMMM YYYY').replace(`${moment().year()}`, '').trim()
  )
}

const DayPickerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .DayPickerNavigation {
    top: 12px;
  }
`

type Action =
  | {
      type: 'UPDATE_RANGE_TYPE'
      payload: RangeType
    }
  | {
      type: 'UPDATE_DATES'
      payload: {
        startDate: Moment
        endDate: Moment
      }
    }
  | {
      type: 'INC_DATES'
    }
  | {type: 'DEC_DATES'}
  | {
      type: 'RESET_DATES'
    }

type State = {
  startDate: Moment
  endDate: Moment
  rangeType: RangeType
  focusedInput: FocusedInputShape
}

function reducer(state: State, action: Action): State {
  if (action.type === 'UPDATE_DATES') {
    const {startDate, endDate} = action.payload

    if (state.rangeType === 'CUSTOM') {
      if (state.focusedInput === START_DATE) {
        return {
          ...state,
          startDate: moment(startDate).startOf('d'),
          endDate: moment(startDate).endOf('d'),
          focusedInput: END_DATE,
        }
      } else {
        return {
          ...state,
          endDate: endDate ? moment(endDate).endOf('d') : moment(state.startDate).endOf('d'),
          focusedInput: START_DATE,
        }
      }
    }

    return {
      ...state,
      startDate: getStartDateFromRangeType(state.rangeType, startDate),
      endDate: getEndDateFromRangeType(state.rangeType, startDate),
    }
  }

  if (action.type === 'RESET_DATES') {
    return {
      ...state,
      startDate: null,
      endDate: null,
      focusedInput: START_DATE,
    }
  }

  if (action.type === 'INC_DATES') {
    if (state.rangeType !== 'DAY') {
      throw new Error(`Incompatible action ${action.type} with range type ${state.rangeType}`)
    }

    const nextDate = moment(state.startDate).add(1, 'day')

    return {
      ...state,
      startDate: moment(nextDate).startOf('d'),
      endDate: moment(nextDate).endOf('d'),
      focusedInput: START_DATE,
    }
  }

  if (action.type === 'DEC_DATES') {
    if (state.rangeType !== 'DAY') {
      throw new Error(`Incompatible action ${action.type} with range type ${state.rangeType}`)
    }

    const nextDate = moment(state.startDate).subtract(1, 'day')

    return {
      ...state,
      startDate: moment(nextDate).startOf('d'),
      endDate: moment(nextDate).endOf('d'),
      focusedInput: START_DATE,
    }
  }

  if (action.type === 'UPDATE_RANGE_TYPE') {
    return {
      startDate: getStartDateFromRangeType(action.payload),
      endDate: getEndDateFromRangeType(action.payload),
      rangeType: action.payload,
      focusedInput: START_DATE,
    }
  }

  return state
}

const DateSummary = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding: 6px;
`

const RangeTypeButtonsWrapper = styled.div`
  display: flex;
  > div {
    border-radius: 0;
    :first-child {
      border-radius: 16px 0 0 16px;
    }
    :last-child {
      border-radius: 0 16px 16px 0;
    }

    margin: 0 1px 0 1px;
    :first-child {
      margin: 0 1px 0 0;
    }
    :last-child {
      margin: 0 0 0 1px;
    }
  }
`

const RangeTypeButton = styled.div<{$isSelected?: boolean}>`
  padding: 8px 16px;
  background: ${({$isSelected}) => ($isSelected ? palette.amblea.tea[600] : palette.amblea.grey[200])};
  color: ${({$isSelected}) => ($isSelected ? palette.amblea.white : palette.amblea.grey[600])};
  :hover {
    background: ${({$isSelected}) => ($isSelected ? palette.amblea.tea[600] : palette.amblea.grey[200])};
    color: ${({$isSelected}) => ($isSelected ? palette.amblea.white : palette.amblea.blue[800])};
  }
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

const DayPickerHeader = styled.div<{$isMobile?: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: ${({$isMobile}) => ($isMobile ? 'center' : 'space-between')};
  width: 100%;
  padding: 0;
`

type RangeType = 'DAY' | 'WEEK' | 'CUSTOM'

const getStartDateFromRangeType = (rangeType: RangeType, date?: Moment): Moment => {
  const date_ = date?.clone() ?? moment()

  switch (rangeType) {
    case 'DAY':
    case 'CUSTOM':
      return moment(date_).startOf('day')
    case 'WEEK':
      return moment(date_).startOf('week')
  }
  assertUnreachable(rangeType)
}

const getEndDateFromRangeType = (rangeType: RangeType, date?: Moment): Moment => {
  const date_ = date?.clone() ?? moment()

  switch (rangeType) {
    case 'DAY':
      return moment(date_).endOf('day')
    case 'WEEK':
      return moment(date_).endOf('week')
    case 'CUSTOM':
      return date ? moment(date_).endOf('day') : moment().endOf('day').add(10, 'years')
  }
  assertUnreachable(rangeType)
}

const isInSamePeriod = (rangeType: RangeType, startDate: Moment, endDate: Moment): boolean => {
  switch (rangeType) {
    case 'DAY':
      return startDate.isSame(moment(), 'day')
    case 'CUSTOM':
      return (
        startDate.isSame(getStartDateFromRangeType(rangeType), 'day') &&
        endDate.isSame(getEndDateFromRangeType(rangeType), 'day')
      )
    case 'WEEK':
      return startDate.isSame(moment(), 'week')
  }
  assertUnreachable(rangeType)
}

const DateTitle = styled(Text).attrs({t: 'h2'})`
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
`

type FlatButtonProps = React.ComponentProps<typeof FlatButton>
const DashboardButton = ({title, onClick}: {title: string} & FlatButtonProps) => (
  <FlatButton
    label={<DateTitle color={palette.amblea.blue[800]}>{capitalize(title)}</DateTitle>}
    icon={<DropdownArrowIcon />}
    color={palette.amblea.grey[600]}
    reverse
    onClick={onClick}
  />
)

function useOpenLeft() {
  const buttonRef = React.useRef(null)
  const [openLeft, setOpenLeft] = React.useState(false)

  React.useEffect(() => {
    if (!buttonRef.current) {
      return
    }

    const x = buttonRef.current.getBoundingClientRect?.().x ?? 0
    const width = window.innerWidth ?? 0

    setOpenLeft(x >= width / 2)
  }, [buttonRef, setOpenLeft])

  return [buttonRef, openLeft] as const
}

export function DatetimeFilter({
  startDate,
  endDate,
  onDatesChange,
  variant,
  withArrowNav,
}: {
  startDate: Moment
  endDate: Moment
  onDatesChange: (args: {startDate: Moment; endDate: Moment}) => void
  variant?: 'single-day'
  withArrowNav?: boolean
}) {
  const isMobile = useMobile()
  const screenClass = useScreenClass()
  const tablet = isIn(['sm'], screenClass)

  const [state, dispatch] = React.useReducer(reducer, {
    startDate,
    endDate,
    rangeType: variant === 'single-day' ? 'DAY' : 'CUSTOM',
    focusedInput: START_DATE,
  })

  const onSave = React.useCallback(() => {
    if (!onDatesChange) {
      return
    }

    onDatesChange({
      startDate: state.startDate,
      endDate: state.endDate || state.startDate,
    })
  }, [onDatesChange, state.endDate, state.startDate])

  const startDateLabel = state.startDate ? state.startDate.format('DD MMM') : 'Début'
  const endDateLabel = state.endDate ? state.endDate.format('DD MMM') : 'Fin'

  const rangeTypes: RangeType[] = variant === 'single-day' ? ['DAY'] : ['DAY', 'WEEK', 'CUSTOM']
  const rangeTypeLabels: Record<RangeType, string> = {
    DAY: "Aujourd'hui",
    WEEK: 'Cette semaine',
    CUSTOM: "À partir d'aujourd'hui",
  } as const

  const buttonLabel = (() => {
    if (state.startDate && state.endDate && isInSamePeriod(state.rangeType, state.startDate, state.endDate)) {
      return rangeTypeLabels[state.rangeType]
    }

    if (state.startDate && state.startDate.isSame(state.endDate, 'd')) {
      // ? withArrowNav implies DAY rangeType
      if (withArrowNav) {
        return `${state.startDate.format('ddd DD MMM')}`
      }
      return startDateLabel
    }

    if (!state.startDate && !state.endDate) {
      return 'Dates'
    }

    return [startDateLabel, endDateLabel].join(' - ')
  })()
  const selected = Boolean(startDate || endDate)
  const [buttonWrapperRef, openLeft] = useOpenLeft()

  return (
    <AutoLayout
      $p={isMobile ? '6px' : '8px'}
      $row
      $gap={withArrowNav ? '8px' : 'inherit'}
      $align={withArrowNav ? 'center' : 'flex-start'}
    >
      {withArrowNav && (
        <AutoLayout>
          <FlatButton
            onClick={() => {
              const nextState = reducer(state, {type: 'DEC_DATES'})
              dispatch({type: 'DEC_DATES'})
              onDatesChange(nextState)
            }}
            color={palette.amblea.tea[600]}
            icon={<ArrowLeftIcon />}
          />
          <FlatButton
            onClick={() => {
              const nextState = reducer(state, {type: 'INC_DATES'})
              dispatch({type: 'INC_DATES'})
              onDatesChange(nextState)
            }}
            color={palette.amblea.tea[600]}
            icon={<ArrowRightIcon />}
          />
        </AutoLayout>
      )}
      <div ref={buttonWrapperRef}>
        <DropdownFilter
          title="Choix de la période"
          label={buttonLabel}
          selected={selected}
          onSave={onSave}
          onClear={variant === 'single-day' ? undefined : () => dispatch({type: 'RESET_DATES'})}
          mobile={isMobile}
          button={
            withArrowNav ? (
              <DashboardButton title={buttonLabel} />
            ) : (
              <FilterButton label={buttonLabel} isActive={selected} />
            )
          }
          openLeft={openLeft}
        >
          {isMobile ? (
            <DateSummary>
              <Andiv column p="8px">
                <Text t="body2" color={state.startDate ? palette.amblea.blue[800] : palette.amblea.grey[600]}>
                  {state.startDate ? state.startDate.format('DD MMM') : 'Date de début'}
                </Text>
              </Andiv>
              <HSpace $px={32} />
              <Andiv column p="8px">
                <Text t="body2" color={palette.amblea.blue[800]}>
                  →
                </Text>
              </Andiv>
              <HSpace $px={32} />
              <Andiv column p="8px">
                <Text t="body2" color={state.endDate ? palette.amblea.blue[800] : palette.amblea.grey[600]}>
                  {state.endDate ? state.endDate.format('DD MMM') : 'Date de fin'}
                </Text>
              </Andiv>
            </DateSummary>
          ) : null}
          <DayPickerWrapper>
            <DayPickerHeader $isMobile={isMobile}>
              {!isMobile ? (
                <FlatButton
                  label={rangeTypeLabels[state.rangeType]}
                  onClick={() => dispatch({type: 'UPDATE_RANGE_TYPE', payload: state.rangeType})}
                />
              ) : null}
              {variant !== 'single-day' && (
                <RangeTypeButtonsWrapper>
                  {rangeTypes.map(rangeType => {
                    const rangeTypeLabel = (() => {
                      switch (rangeType) {
                        case 'DAY':
                          return 'Jour'
                        case 'WEEK':
                          return 'Semaine'
                        default:
                          return 'Personnalisé'
                      }
                    })()

                    return (
                      <RangeTypeButton
                        key={rangeType}
                        onClick={() => dispatch({type: 'UPDATE_RANGE_TYPE', payload: rangeType})}
                        $isSelected={rangeType === state.rangeType}
                      >
                        {rangeTypeLabel}
                      </RangeTypeButton>
                    )
                  })}
                </RangeTypeButtonsWrapper>
              )}
            </DayPickerHeader>
            {/* @ts-ignore FixType missing initial visible month */}
            <DayPickerRangeController
              // ? Control and update selected dates.
              startDate={state.startDate}
              endDate={state.endDate}
              onDatesChange={dates => dispatch({type: 'UPDATE_DATES', payload: dates})}
              // ? Control and update which date is selected next.
              focusedInput={state.focusedInput}
              // ? Never close the calendar. It's the DropdownFilter job.
              keepOpenOnDateSelect
              // ? Filter unselectable dates.
              isOutsideRange={date => {
                if (state.focusedInput === END_DATE && moment(date).isBefore(moment(state.startDate), 'day')) {
                  return true
                }
                return false
              }}
              // ? Customize the rendering to match Amblea's theme.
              renderDayContents={renderDay}
              renderMonthText={renderMonth}
              navPrev={<PrevIcon />}
              navNext={<NextIcon />}
              hideKeyboardShortcutsPanel
              noBorder
              numberOfMonths={tablet || !isMobile ? 2 : 1}
              minimumNights={0}
              onFocusChange={() => null}
            />
          </DayPickerWrapper>
          {isMobile ? (
            <AppRow justify="center">
              <FlatButton
                label={rangeTypeLabels[state.rangeType]}
                onClick={() => dispatch({type: 'UPDATE_RANGE_TYPE', payload: state.rangeType})}
              />
            </AppRow>
          ) : null}
        </DropdownFilter>
      </div>
    </AutoLayout>
  )
}
