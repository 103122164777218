import React from 'react'
import {ApolloProvider, ApolloClient, NormalizedCacheObject} from '@apollo/client'
import withNextApollo from 'next-with-apollo'

import {FixType} from '@ambler/shared'
import {createApolloClient} from './apollo-client'

function getDisplayName(WrappedComponent: FixType) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

const initializeApolloClient = (initialState: FixType = null): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient = apolloClient ?? createApolloClient(initialState)
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({...existingCache, ...initialState})
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }
  return _apolloClient
}

const render = ({Page, props}: FixType) => {
  return (
    <ApolloProvider client={props.apollo}>
      <Page {...props} />
    </ApolloProvider>
  )
}

/**
 * next-with-apollo is a simple HoC managing the apollo instance for both the
 * server and client, accepting optionals parameters (mainly getInitialProps
 * and getDataFromTree resulting in the opting out of SSG optimizations).
 * See https://github.com/lfades/next-with-apollo/blob/master/src/withApollo.tsx
 */
const withNextApolloClient = withNextApollo(
  ({initialState}) => {
    return initializeApolloClient(initialState)
  },
  {
    render,
  },
)

/**
 * Wrap the library's withApollo HoC in order to add dev warnings on any use of
 * getInitialProps or getDataFromTree (before it is eventually removed from
 * Next altogether) opting out of SSG optimizations.
 * See https://nextjs.org/docs/api-reference/data-fetching/getInitialProps#caveats
 *
 * Probably replaceable by new methods: getStaticProps (SSG), getStaticPaths (SSG)
 * and getServerSideProps (SSR).
 * See https://nextjs.org/docs/basic-features/data-fetching#getserversideprops-server-side-rendering
 **/
export const withApollo = (PageComponent: FixType, options?: FixType) => {
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component'
    if (options?.getInitialProps || options?.getDataFromTree || PageComponent.getInitialProps) {
      console.warn(`Warning: Automatic Static Optimization disabled for ${displayName}.`)
    }
  }

  PageComponent.displayName = `withApollo(${getDisplayName(PageComponent)})`

  return withNextApolloClient(PageComponent, options)
}
