import {palette} from '@ambler/andive-next'
import {
  assertUnreachable,
  BookingRequestStatus,
  ExportRequestStatus,
  ManualInvoiceStatus,
  MissionStatus,
  PassengerStatus,
  dispatchStatuses,
  isRefused,
  isAccepted,
  isPending,
  AmblerInvoiceStatus,
  isCounterOfferPending,
} from '@ambler/shared'

import {InvoiceStatus} from '@prisma/client'

export interface Colors {
  color: string
  backgroundColor: string
}

export const warningColors = {color: palette.amblea.orange[700], backgroundColor: palette.amblea.orange[200]}
export const successColors = {color: palette.amblea.green[700], backgroundColor: palette.amblea.green[200]}
export const errorColors = {color: palette.amblea.red[700], backgroundColor: palette.amblea.red[200]}
export const staleColors = {color: palette.amblea.blue[800], backgroundColor: palette.amblea.grey[100]}
export const actionColors = {color: palette.amblea.tea[600], backgroundColor: palette.amblea.tea[200]}

enum DocumentFaviconColor {
  GREY = '/static/favicon-doc-grey-48x48.png',
  YELLOW = '/static/favicon-doc-orange-48x48.png',
  RED = '/static/favicon-doc-red-48x48.png',
  GREEN = '/static/favicon-doc-green-48x48.png',
}

/* INVOICE */
export function getInvoiceStatusColors(status: InvoiceStatus): Colors {
  switch (status) {
    case 'WAIT_TC_RELAUNCH':
    case 'WAIT_PASSENGERS_CONFIRMATION':
      return warningColors
    case 'WAIT_TPTR_APPROVAL':
    case 'WAIT_HPTL_APPROVAL':
    case 'APPROVED':
      return successColors
    case 'DROPPED':
    case 'CANCELED':
      return errorColors
    case 'EDIT_DROPPED':
    case 'EDIT_DROPPED_AMBLER':
    case 'REPLACED':
      return staleColors
  }

  assertUnreachable(status)
}

export function get3PInvoiceStatusFaviconColors(status: InvoiceStatus): DocumentFaviconColor {
  switch (status) {
    case 'WAIT_TC_RELAUNCH':
    case 'WAIT_PASSENGERS_CONFIRMATION':
      return DocumentFaviconColor.YELLOW
    case 'WAIT_TPTR_APPROVAL':
    case 'WAIT_HPTL_APPROVAL':
    case 'APPROVED':
      return DocumentFaviconColor.GREEN
    case 'DROPPED':
    case 'CANCELED':
      return DocumentFaviconColor.RED
    case 'EDIT_DROPPED':
    case 'EDIT_DROPPED_AMBLER':
    case 'REPLACED':
      return DocumentFaviconColor.GREY
  }

  assertUnreachable(status)
}

/* MANUAL INVOICE */
export function getManualInvoiceStatusColors(status: ManualInvoiceStatus): Colors {
  switch (status) {
    case 'WAIT_AMBLER_APPROVAL':
    case 'WAIT_AUTO_APPROVAL':
      return warningColors
    case 'WAIT_HPTL_APPROVAL':
    case 'APPROVED':
      return successColors
    case 'REFUSED':
    case 'DROPPED':
      return errorColors
    case 'CREATED':
      return staleColors
  }

  assertUnreachable(status)
}

export function getN3PInvoiceStatusFaviconColors(status: ManualInvoiceStatus): DocumentFaviconColor {
  switch (status) {
    case 'WAIT_AMBLER_APPROVAL':
    case 'WAIT_AUTO_APPROVAL':
      return DocumentFaviconColor.YELLOW
    case 'WAIT_HPTL_APPROVAL':
    case 'APPROVED':
      return DocumentFaviconColor.GREEN
    case 'REFUSED':
    case 'DROPPED':
      return DocumentFaviconColor.RED
    case 'CREATED':
      return DocumentFaviconColor.GREY
  }

  assertUnreachable(status)
}

/* AMBLER INVOICE */
export function getAmblerInvoiceStatusColors(status: AmblerInvoiceStatus): Colors {
  switch (status) {
    case 'WAIT_TC_RELAUNCH':
    case 'WAIT_AUTO_RELAUNCH':
      return warningColors
    case 'APPROVED':
      return successColors
    case 'REPLACED':
    case 'CANCELED':
    case 'DROPPED':
      return errorColors
    case 'WAIT_INVOICEE_APPROVAL':
      return staleColors
  }

  assertUnreachable(status)
}

export function getAmblerInvoiceStatusFaviconColors(status: AmblerInvoiceStatus): DocumentFaviconColor {
  switch (status) {
    case 'WAIT_TC_RELAUNCH':
    case 'WAIT_AUTO_RELAUNCH':
      return DocumentFaviconColor.YELLOW
    case 'APPROVED':
      return DocumentFaviconColor.GREEN
    case 'REPLACED':
    case 'CANCELED':
    case 'DROPPED':
      return DocumentFaviconColor.RED
    case 'WAIT_INVOICEE_APPROVAL':
      return DocumentFaviconColor.GREY
  }

  assertUnreachable(status)
}

/* MISSION */
export function getMissionStatusColors(status: MissionStatus): Colors {
  switch (status) {
    case 'CREATED':
    case 'EDITED':
    case 'WAIT_DISPATCH_STATUS':
    case 'ON_HOLD': // deprecated
    case 'DELAYED':
      return staleColors
    case 'WAIT_HPTL_DISPATCH':
    case 'WAIT_TC_DISPATCH':
    case 'PRIORITY_DISPATCH':
    case 'URGENT_DISPATCH':
    case 'WAIT_AUTO_TPTR_APPROVAL':
    case 'PREBOOKED':
      return warningColors
    case 'BOOKED':
      return successColors
    case 'ABORTED_NOT_IN_ZONE':
    case 'ABORTED_EDIT':
    case 'ABORTED_MERGED':
    case 'EDITED_IMPROVED':
    case 'ABORTED_BOUNCE':
    case 'REPLACED':
    case 'DROPPED':
    case 'TC_DISPATCH_TIMEOUT':
    case 'TC_TPTR_NOT_FOUND':
    case 'HPTL_TPTR_NOT_FOUND':
    case 'TC_HPTL_TPTR_NOT_FOUND':
    case 'HPTL_DISPATCH_TIMEOUT':
    case 'UNPREBOOKED':
      return errorColors
    case 'REQUESTED':
      return warningColors
  }

  assertUnreachable(status)
}

enum PassengerFaviconColor {
  GREY = '/static/favicon-pin-grey-48x48.png',
  YELLOW = '/static/favicon-pin-orange-48x48.png',
  RED = '/static/favicon-pin-red-48x48.png',
  GREEN = '/static/favicon-pin-green-48x48.png',
}

/* PASSENGER FAVICON COLOR */
export function getPassengerStatusFaviconColors(status: PassengerStatus): PassengerFaviconColor {
  switch (status) {
    case 'CREATED':
    case 'ON_HOLD': // deprecated
    case 'DELAYED':
      return PassengerFaviconColor.GREY
    case 'DISPATCHING':
    case 'SUPPORT':
    case 'WAIT_TPTR_CONFIRMATION':
    case 'WAIT_TC_CONFIRMATION':
    case 'WAIT_HPTL_CONFIRMATION':
      return PassengerFaviconColor.YELLOW
    case 'BOOKED':
    case 'PREBOOKED':
    case 'RUNNING':
    case 'ARRIVED':
    case 'CONFIRMED':
      return PassengerFaviconColor.GREEN
    case 'HPTL_EDIT_DROP':
    case 'HPTL_DROP':
    case 'HPTL_EDIT_CANCEL':
    case 'HPTL_CANCEL':
    case 'TC_HPTL_CANCEL':
    case 'PTNT_CANCEL':
    case 'TC_PTNT_CANCEL':
    case 'UNBOOKED':
    case 'UNPREBOOKED':
    case 'TC_PTNT_DROP':
    case 'TC_HPTL_DROP':
    case 'PTNT_NORIDE_HPTL_FAULT':
    case 'PTNT_DROP':
    case 'HPTL_NORIDE_HPTL_FAULT':
    case 'TPTR_NORIDE_HPTL_FAULT':
    case 'PTNT_NORIDE_PTNT_FAULT':
    case 'TPTR_NORIDE_PTNT_FAULT':
    case 'PTNT_NORIDE_TPTR_FAULT':
    case 'TPTR_NORIDE_TPTR_FAULT':
    case 'HPTL_NORIDE_PTNT_FAULT':
    case 'HPTL_NORIDE_TPTR_FAULT':
    case 'HPTL_NORIDE_AMBLER_FAULT':
    case 'TPTR_NORIDE_AMBLER_FAULT':
    case 'PTNT_NORIDE_AMBLER_FAULT':
    case 'TC_DISPATCH_TIMEOUT':
    case 'TC_TPTR_NOT_FOUND':
    case 'HPTL_TPTR_NOT_FOUND':
    case 'TC_HPTL_TPTR_NOT_FOUND':
    case 'HPTL_DISPATCH_TIMEOUT':
    case 'ABORTED':
    case 'ABORTED_MERGED':
    case 'EDITED_IMPROVED':
      return PassengerFaviconColor.RED
    case 'CHANGED':
    case 'REQUESTED':
      return PassengerFaviconColor.YELLOW
  }
  assertUnreachable(status)
}

enum MissionFaviconColor {
  GREY = '/static/favicon-gyro-grey-48x48.png',
  YELLOW = '/static/favicon-gyro-orange-48x48.png',
  RED = '/static/favicon-gyro-red-48x48.png',
  GREEN = '/static/favicon-gyro-green-48x48.png',
}

/* MISSION FAVICON COLOR */
export function getMissionStatusFaviconColors(status: MissionStatus): MissionFaviconColor {
  switch (status) {
    case 'CREATED':
    case 'EDITED':
    case 'WAIT_DISPATCH_STATUS':
    case 'ON_HOLD': // deprecated
    case 'DELAYED':
      return MissionFaviconColor.GREY
    case 'WAIT_HPTL_DISPATCH':
    case 'WAIT_TC_DISPATCH':
    case 'PRIORITY_DISPATCH':
    case 'URGENT_DISPATCH':
    case 'WAIT_AUTO_TPTR_APPROVAL':
    case 'PREBOOKED':
      return MissionFaviconColor.YELLOW
    case 'BOOKED':
      return MissionFaviconColor.GREEN
    case 'ABORTED_NOT_IN_ZONE':
    case 'ABORTED_EDIT':
    case 'ABORTED_MERGED':
    case 'EDITED_IMPROVED':
    case 'ABORTED_BOUNCE':
    case 'REPLACED':
    case 'DROPPED':
    case 'TC_DISPATCH_TIMEOUT':
    case 'TC_TPTR_NOT_FOUND':
    case 'HPTL_TPTR_NOT_FOUND':
    case 'TC_HPTL_TPTR_NOT_FOUND':
    case 'HPTL_DISPATCH_TIMEOUT':
    case 'UNPREBOOKED':
      return MissionFaviconColor.RED
    case 'REQUESTED':
      return MissionFaviconColor.YELLOW
  }
  assertUnreachable(status)
}

/* A4D */
export function getA4DMissionStatusColors(status: MissionStatus): Colors {
  // Different displays from A4D and regulars missions
  if (dispatchStatuses.includes(status)) {
    return errorColors
  } else {
    return getMissionStatusColors(status)
  }
}

export function getA4DPassengerStatusColor(
  status: 'CONFIRMED' | 'BOOKED' | 'CANCELED' | 'FINISHED' | 'NORIDE' | 'INCIDENT' | 'EDITED',
): Colors {
  switch (status) {
    case 'CONFIRMED':
    case 'FINISHED':
      return successColors

    case 'CANCELED':
    case 'NORIDE':
      return errorColors
    case 'EDITED':
    case 'BOOKED':
    case 'INCIDENT':
      return warningColors
  }
  assertUnreachable(status)
}

/* PASSENGER */
export function getPassengerStatusColors(status: PassengerStatus): Colors {
  switch (status) {
    case 'CREATED':
    case 'ON_HOLD': // deprecated
    case 'DELAYED':
      return staleColors
    case 'PREBOOKED':
    case 'DISPATCHING':
    case 'SUPPORT':
    case 'WAIT_TPTR_CONFIRMATION':
    case 'WAIT_TC_CONFIRMATION':
    case 'WAIT_HPTL_CONFIRMATION':
      return warningColors
    case 'BOOKED':
    case 'RUNNING':
    case 'ARRIVED':
    case 'CONFIRMED':
      return successColors
    case 'HPTL_EDIT_DROP':
    case 'HPTL_DROP':
    case 'HPTL_EDIT_CANCEL':
    case 'HPTL_CANCEL':
    case 'TC_HPTL_CANCEL':
    case 'PTNT_CANCEL':
    case 'TC_PTNT_CANCEL':
    case 'UNBOOKED':
    case 'UNPREBOOKED':
    case 'TC_PTNT_DROP':
    case 'TC_HPTL_DROP':
    case 'PTNT_NORIDE_HPTL_FAULT':
    case 'PTNT_DROP':
    case 'HPTL_NORIDE_HPTL_FAULT':
    case 'TPTR_NORIDE_HPTL_FAULT':
    case 'PTNT_NORIDE_PTNT_FAULT':
    case 'TPTR_NORIDE_PTNT_FAULT':
    case 'PTNT_NORIDE_TPTR_FAULT':
    case 'TPTR_NORIDE_TPTR_FAULT':
    case 'HPTL_NORIDE_PTNT_FAULT':
    case 'HPTL_NORIDE_TPTR_FAULT':
    case 'HPTL_NORIDE_AMBLER_FAULT':
    case 'TPTR_NORIDE_AMBLER_FAULT':
    case 'PTNT_NORIDE_AMBLER_FAULT':
    case 'TC_DISPATCH_TIMEOUT':
    case 'TC_TPTR_NOT_FOUND':
    case 'HPTL_TPTR_NOT_FOUND':
    case 'TC_HPTL_TPTR_NOT_FOUND':
    case 'HPTL_DISPATCH_TIMEOUT':
    case 'ABORTED':
    case 'ABORTED_MERGED':
    case 'EDITED_IMPROVED':
      return errorColors
    case 'CHANGED':
    case 'REQUESTED':
      return warningColors
  }

  assertUnreachable(status)
}

export function getBookingRequestsColors(status: BookingRequestStatus): Colors {
  if (isAccepted(status)) {
    return successColors
  }
  if (isRefused(status)) {
    return errorColors
  }
  if (isPending(status)) {
    return warningColors
  }
  if (isCounterOfferPending(status)) {
    return actionColors
  }
  switch (status) {
    case 'WAITING':
    case 'DUPLICATED':
      return warningColors
    case 'EXPIRED':
    case 'NOT_SENT':
      return staleColors
  }

  assertUnreachable(status)
}

/* EXPORT REQUESTS */
export const getExportRequestColors = (exportRequestStatus: ExportRequestStatus) => {
  switch (exportRequestStatus) {
    case 'ERRORED':
      return errorColors
    case 'DONE':
      return successColors
    case 'PENDING':
      return staleColors
    case 'PROCESSING':
      return warningColors
  }
  assertUnreachable(exportRequestStatus)
}
