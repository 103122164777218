import {assertUnreachable} from '@ambler/shared'
import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  TypedDocumentNode,
  useMutation as useMutation_,
} from '@apollo/client'
import {toastInfo, toastSuccess, toastError} from '@ambler/andive-next'
import Sentry from '../lib/sentry'

export const DEFAULT_ERR_MESSAGE = 'Une erreur est survenue !'

type ToastLevel = 'info' | 'success'
const toast = (level: ToastLevel, message: string) => {
  switch (level) {
    case 'info':
      return toastInfo(message)
    case 'success':
      return toastSuccess(message)
  }
  assertUnreachable(level)
}

type useMutationParams<TData, TVariables> = {
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>
  mutationOptions?: MutationHookOptions<TData, TVariables>
  success?: {
    message?: string | ((data: TData) => string)
    level?: 'info' | 'success'
    callback?: (data: TData) => Promise<any>
  }
  error?: {
    message?: string | ((error: ApolloError) => string)
    callback?: (error: ApolloError) => Promise<any>
  }
  final?: () => Promise<any>
}

export const useMutation = <TData, TVariables>({
  mutation,
  mutationOptions = {},
  success,
  error,
  final,
}: useMutationParams<TData, TVariables>) => {
  return useMutation_<TData, TVariables>(mutation, {
    onCompleted: async (data: TData) => {
      if (success?.message) {
        const msg = typeof success.message === 'function' ? success.message(data) : success.message
        toast(success.level || 'success', msg)
      }
      try {
        await success?.callback?.(data)
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      } finally {
        final?.()
      }
    },
    onError: async apolloError => {
      // ? passing an explicit null value to the error message will prevent the toast from being displayed
      // ? undefined will display the default error message
      if (error?.message !== null) {
        const msg = typeof error?.message === 'function' ? error.message(apolloError) : error?.message
        toastError(msg ?? DEFAULT_ERR_MESSAGE)
      }
      const errors = [...apolloError.graphQLErrors, ...apolloError.clientErrors, apolloError.networkError].filter(
        Boolean,
      )
      errors.forEach(err => {
        console.error(err)
      })
      try {
        await error?.callback?.(apolloError)
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      } finally {
        final?.()
      }
    },
    ...mutationOptions,
  })
}
