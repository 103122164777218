import React from 'react'

import {
  AmblerInvoiceStatus,
  FixType,
  InvoiceStatus,
  ManualInvoiceStatus,
  MissionStatus,
  PassengerStatus,
} from '@ambler/shared'
import {
  get3PInvoiceStatusFaviconColors,
  getAmblerInvoiceStatusFaviconColors,
  getMissionStatusFaviconColors,
  getN3PInvoiceStatusFaviconColors,
  getPassengerStatusFaviconColors,
} from '../lib/colors'

type FaviconContentType = {
  register: (href: string) => () => void
}

const FaviconContext = React.createContext<FaviconContentType>(null)

export function FaviconProvider(props: FixType) {
  const [mountedInstances, setMountedInstances] = React.useState([])

  const faviconHref = React.useMemo(() => {
    return mountedInstances[mountedInstances.length - 1]?.href ?? '/static/favicon-64x64.png'
  }, [mountedInstances])

  const register = React.useCallback((href: string) => {
    const instance = {href}

    setMountedInstances(prevMountedInstances => [...prevMountedInstances, instance])

    return () => {
      setMountedInstances(prevMountedInstances => {
        const index = prevMountedInstances.indexOf(instance)
        return [...prevMountedInstances.slice(0, index), ...prevMountedInstances.slice(index + 1)]
      })
    }
  }, [])

  React.useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'shortcut icon'
    link.href = faviconHref
    document.getElementsByTagName('head')[0].appendChild(link)

    return () => {
      document.getElementsByTagName('head')[0].removeChild(link)
    }
  }, [faviconHref])

  return <FaviconContext.Provider value={{register}}>{props.children}</FaviconContext.Provider>
}

type FaviconPropTypes = {
  href: string
}

function Favicon(props: FaviconPropTypes): FixType {
  const {register} = React.useContext(FaviconContext)

  React.useEffect(() => register(props.href), [props.href, register])

  return null
}

export function FaviconMt() {
  return <Favicon href="/static/favicon-ts-blue-48x48.png" />
}

export function FaviconMf() {
  return <Favicon href="/static/favicon-es-blue-48x48.png" />
}

type FaviconMissionPropTypes = {
  status: MissionStatus
}

export function FaviconMission(props: FaviconMissionPropTypes) {
  return <Favicon href={props.status ? getMissionStatusFaviconColors(props.status) : undefined} />
}

type FaviconTripPropTypes = {
  status: PassengerStatus
}

export function FaviconTrip(props: FaviconTripPropTypes) {
  return <Favicon href={props.status ? getPassengerStatusFaviconColors(props.status) : undefined} />
}

type Favicon3PInvoicePropTypes = {
  status: InvoiceStatus
}

export function Favicon3PInvoice(props: Favicon3PInvoicePropTypes) {
  return <Favicon href={props.status ? get3PInvoiceStatusFaviconColors(props.status) : undefined} />
}

type FaviconN3PInvoicePropTypes = {
  status: ManualInvoiceStatus
}

export function FaviconN3PInvoice(props: FaviconN3PInvoicePropTypes) {
  return <Favicon href={props.status ? getN3PInvoiceStatusFaviconColors(props.status) : undefined} />
}

type FaviconAmblerInvoicePropTypes = {
  status: AmblerInvoiceStatus
}

export function FaviconAmblerInvoice(props: FaviconAmblerInvoicePropTypes) {
  return <Favicon href={props.status ? getAmblerInvoiceStatusFaviconColors(props.status) : undefined} />
}
